<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "AuthIndex",
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
@import "@/styles/auth.scss";
</style>
